var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('trac-loading',{staticClass:"pt-64"}):_c('main',{staticClass:"mr-4 md:mr-0"},[_c('trac-back-button',[_vm._v("Payments")]),_c('div',{staticClass:"md:mt-8"},[_c('div',{staticClass:"flex justify-between"},[_c('div',{staticClass:"mt-3 flex items-center"}),(false)?_c('div',{staticClass:"max-w-xs w-full px-6 relative small-shadow rounded-md flex items-center py-4 bg-white"},[_c('img',{staticClass:"w-10 ml-2 h-10",attrs:{"src":require("@/assets/svg/payoutalert.svg"),"alt":""}}),_c('div',[_c('div',{staticClass:"tiny flex items-center ml-6 pr-10 uppercase font-medium text-primaryBlue"},[_vm._v(" DUE TOMORROW "),_c('svg',{staticClass:"w-2 h-3 ml-2 stroke-current",attrs:{"viewBox":"0 0 7 13","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1 11.5L6.4 6.1L1 0.700001","stroke":"#003283","stroke-width":"1.2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('svg',{staticClass:"w-6 h-6 absolute top-0 right-0 -mr-2 -mt-1",attrs:{"viewBox":"0 0 27 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"13.5","cy":"14","r":"13.5","fill":"#253B95","fill-opacity":"0.33"}}),_c('circle',{attrs:{"cx":"13.5","cy":"14","r":"10.5","fill":"#253B95","fill-opacity":"0.72"}}),_c('circle',{attrs:{"cx":"13.4546","cy":"14","r":"6.54541","fill":"#253B95"}})])]):_vm._e()])]),_c('div',{staticClass:"w-full mt-10"},[_c('div',[_c('div',{staticClass:"grid rounded-tl-md rounded-tr-md border",style:(`grid-template-columns:1fr 1fr `)},_vm._l((_vm.getTableHeader),function(header,index){return _c('div',{key:index,staticClass:"pl-8 py-5 bg-accentLight uppercase text-xs font-medium w-full",class:[
            index == 0 || index == _vm.getTableHeader.length - 1
              ? 'rounded-tl-md rounded-tr-md'
              : '',
            { 'grid-space+': index == 0 },
          ]},[_vm._v(" "+_vm._s(header)+" ")])}),0)]),(_vm.filteredBy && _vm.filteredBy.length)?_c('div',_vm._l((_vm.filteredBy),function(payment,i){return _c('div',{key:i,staticClass:"grid last:border-b border-l hov cursor-pointer border-r even:bg-veryLight items-center",class:[
          i == _vm.payments.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
        ],style:(`grid-template-columns: 1fr 1fr `),on:{"click":function($event){return _vm.$router.push({
            name: 'PayoutDetails',
            params: {
              payoutId: payment.payout_id,
            },
          })}}},[_c('div',{staticClass:"w-full flex items-center py-4 pl-8 text-xs text-accentDark font-normal"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(payment.total_value_payments))+" ")]),_c('div',{staticClass:"w-full py-4 pl-8 text-xs text-accentDark font-normal flex-col"},[_vm._v(" "+_vm._s(payment.number_of_payments)+" payments ")])])}),0):_c('div',[_c('div',{staticClass:"border py-3 pl-5 text-xs border-t-0"},[_c('trac-center-data',[_c('div',{staticClass:"h-40 flex items-center text-lg text-gray-600"},[_vm._v(" There is currently no data available. ")])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }